import { graphql } from "gatsby"
import React from "react"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import "../css/all.css"
import "../css/index"
import "../scss/index.scss"
import locales from "../translate/locales"

export const query = graphql`
  query {
    allContentfulAboutUsPage {
      edges {
        node {
          videoLink

          introTextAr {
            childMarkdownRemark {
              html
            }
          }

          introTextEn {
            childMarkdownRemark {
              html
            }
          }

          ourTeamEn {
            childMarkdownRemark {
              html
            }
          }

          ourTeamAr {
            childMarkdownRemark {
              html
            }
          }

          ourMessageEn {
            childMarkdownRemark {
              html
            }
          }

          ourMessageAr {
            childMarkdownRemark {
              html
            }
          }

          trainingDiplomasEn {
            childMarkdownRemark {
              html
            }
          }

          trainingDiplomasAr {
            childMarkdownRemark {
              html
            }
          }

          masteryExpertsAndTeamsAr {
            childMarkdownRemark {
              html
            }
          }

          masteryExpertsAndTeamsEn {
            childMarkdownRemark {
              html
            }
          }

          rateUsTextAr {
            childMarkdownRemark {
              html
            }
          }

          rateUsTextEn {
            childMarkdownRemark {
              html
            }
          }

          ourServicesSection {
            titleAr
            titleEn
            iconClassName
            iconColor
          }

          rateUsTitleAr
          rateUsTitleEn

          whatWeSaidSection {
            theSayingEn
            theSayingAr
            theSayingJobEn
            theSayingJobAr
            contentTextAr {
              childMarkdownRemark {
                html
              }
            }
            contentTextEn {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`

const About = (props: any) => {
  const locale = props.pageContext.locale
  const texts = locales[locale as "en"]
  const pageContent = props.data.allContentfulAboutUsPage.edges[0].node

  const longText = (value: any) => {
    const result = value.childMarkdownRemark.html.split(/\<.*?\>/g)

    return result
  }

  const translate = texts.aboutPageContent

  return (
    <div>
      <Header lang={locale} />

      <div
        className="page-title bg_cover mt-80 pt-140 pb-140"
        style={{ backgroundImage: `url(/images/pexels-photo-290595.jpeg)` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="page-title-item text-center">
                <h3 className="title">{texts.aboutPageContent.aboutUs}</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">{translate.home}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {translate.about}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="access-area pt-120 pb-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="access-thumb">
                <img src="/images/pexels-photo-1170412.jpeg" alt="access" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="access-content">
                <div className="access-mission">
                  <p>
                    {longText(
                      locale == "ar"
                        ? pageContent.introTextAr
                        : pageContent.introTextEn
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="services-area-3 bg_cover">
        <div className="container">
          <div className="row justify-content-center">
            {pageContent.ourServicesSection.map((e: any) => {
              const title = locale == "ar" ? e.titleAr : e.titleEn
              return (
                <div className="col-lg-3 col-md-6 col-sm-9">
                  <div className="single-services text-center mt-30">
                    <i
                      style={{ color: "#2d589a" }}
                      className={e.iconClassName}
                    ></i>
                    <span>{title}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <section className="core-features-area pt-110 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2">
                <h3
                  className="title"
                  style={{
                    textAlign: "center",
                    color: "rgba(13, 23, 48, 0.87)",
                  }}
                >
                  {translate.masteryAcademy}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 pt-40 ">
              <ul
                className="nav nav-pills d-sm-flex d-block text-center  pt-15 "
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item ">
                  <a
                    className="nav-link active"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                  >
                    {translate.ourMessage}
                  </a>
                </li>

                <li className="nav-item margin-24">
                  <a
                    className="nav-link"
                    id="pills-4-tab"
                    data-toggle="pill"
                    href="#pills-4"
                    role="tab"
                    aria-controls="pills-4"
                    aria-selected="false"
                  >
                    {translate.trainingDiplomas}
                  </a>
                </li>
                <li className="nav-item margin-24">
                  <a
                    className="nav-link"
                    id="pills-5-tab"
                    data-toggle="pill"
                    href="#pills-5"
                    role="tab"
                    aria-controls="pills-5"
                    aria-selected="false"
                  >
                    {translate.masteryExpertsAndTeams}
                  </a>
                </li>

                <li className="nav-item margin-24">
                  <a
                    className="nav-link"
                    id="pills-2-tab"
                    data-toggle="pill"
                    href="#pills-2"
                    role="tab"
                    aria-controls="pills-2"
                    aria-selected="false"
                  >
                    {translate.ourTeam}
                  </a>
                </li>
              </ul>
              <div className="tab-content pt-30" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-1"
                  role="tabpanel"
                  aria-labelledby="pills-1-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="features-thumb">
                        <img
                          src="/images/pexels-photo-652347.jpeg"
                          alt="features"
                          width="540"
                          height="356"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="features-content ml-30">
                        <p>
                          {longText(
                            locale == "ar"
                              ? pageContent.ourMessageAr
                              : pageContent.ourMessageEn
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="pills-2"
                  role="tabpanel"
                  aria-labelledby="pills-2-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="features-content ml-30">
                        <p>
                          {longText(
                            locale == "ar"
                              ? pageContent.ourTeamAr
                              : pageContent.ourTeamEn
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="features-thumb">
                        <img
                          src="/images/pexels-photo-652347.jpeg"
                          alt="features"
                          width="540"
                          height="356"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-4"
                  role="tabpanel"
                  aria-labelledby="pills-4-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="features-content ml-30">
                        <p>
                          {longText(
                            locale == "ar"
                              ? pageContent.trainingDiplomasAr
                              : pageContent.trainingDiplomasEn
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="features-thumb">
                        <img
                          src="/images/pexels-photo-267885.jpeg"
                          alt="features"
                          width="540"
                          height="356"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-5"
                  role="tabpanel"
                  aria-labelledby="pills-5-tab"
                >
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="features-thumb">
                        <img
                          src="/images/pexels-photo-256273.jpeg"
                          alt="features"
                          width="540"
                          height="356"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="features-content ml-30">
                        <p>
                          {longText(
                            locale == "ar"
                              ? pageContent.masteryExpertsAndTeamsAr
                              : pageContent.masteryExpertsAndTeamsEn
                          )}
                        </p>

                        <a
                          href={`/${locale}/teacherForm`}
                          style={{
                            width: "50%",
                            textAlign: "center",
                            background: "#004eab",
                            color: "#fff",
                          }}
                        >
                          <i className="fal fa-user"></i>
                          {translate.becomeATeacher}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="about-counter-area bg_cover pt-120 pb-120"
        style={{ backgroundImage: `url(/images/counter-bg-2.png)` }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-counter-content">
                <h3 className="title">
                  {locale == "ar"
                    ? pageContent.rateUsTitleAr
                    : pageContent.rateUsTitleEn}
                </h3>
                <p>
                  {longText(
                    locale == "ar"
                      ? pageContent.rateUsTextAr
                      : pageContent.rateUsTextEn
                  )}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="counter-thumb ml-30">
                <img src="/images/pexels-photo-207691.jpeg" alt="counter" />
                <a className="video-popup" href={pageContent.videoLink}>
                  <i className="fal fa-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="testimonials-area pb-115 bg_cover"
        style={{
          backgroundImage: `url(assets/images/testimonials-pattern.png)`,
          direction: "ltr",
        }}
      >
        <div className="container">
          <div className="row testimonials-active">
            {pageContent.whatWeSaidSection.map((e) => {
              const content = locale == "ar" ? e.contentTextAr : e.contentTextAr
              const theSaying = locale == "ar" ? e.theSayingAr : e.theSayingEn
              const theSayingJob =
                locale == "ar" ? e.theSayingJobAr : e.theSayingJobEn

              return (
                <div className="col-lg-12">
                  <div className="testimonials-content text-center">
                    <i className="fas fa-quote-left"></i>

                    <p>{longText(content)}</p>
                    <img
                      src="/images/nobody_m.original.jpg"
                      alt="testimonials"
                      width="50"
                      style={{ borderRadius: 20 }}
                    />
                    <h5>{theSaying}</h5>
                    <span>{theSayingJob}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <Footer lang={locale} />
    </div>
  )
}
export default About
